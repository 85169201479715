<script lang="ts">
  import Page from '$lib/layout/page/page.svelte';
  import HomeAnimation from '$lib/components/home-animation/home-animation.svelte';
  import PageHero from '$lib/components/page-hero/page-hero.svelte';
  import type { PageData } from './$types';

  export let data: PageData;

  const { page } = data;
  const { hero, title, description, keywords, path, body } = page;
</script>

<PageHero content={hero} home>
  <HomeAnimation slot="home-animation" />
</PageHero>
<Page {body} {path} {keywords} {title} {description} />
